<div class="center mt-16">
  <h5 *ngIf="data.titleText">{{data.titleText}}</h5>
  <span class="block mt-16 mb-16 pre-line" [class.align-left]="data.leftAlign">
    {{data.primaryText}}
    <ng-container *ngIf="data.primaryTextSecondLine">
      <br><br>
      {{data.primaryTextSecondLine}}
    </ng-container>
  </span>
</div>
<div class="flex-column mt-24 flex-center">
  <button class="dialog-btn employer-submit-btn" [class.dialog-right]="!data.submitText" mat-flat-button color="primary"
    (click)="onCTA()">{{data.submitText}}</button>

  <button class="dialog-btn mt-16 pt-8 pb-8" mat-button (click)="onNoClick()"
    *ngIf="data.dismissText">{{data.dismissText ? data.dismissText : 'Dismiss'}}
  </button>
</div>
