import { JwtModule } from '@auth0/angular-jwt';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LayoutService } from './shared/layout.service';
import { InterceptService } from './shared/http.interceptor';
import { RequestConstants } from './constants';
import { FireBaseMessagingService } from './shared/messaging.service';

@NgModule({ declarations: [],
    exports: [], imports: [JwtModule.forRoot({
            config: {
                allowedDomains: [RequestConstants.BASE_URL],
                disallowedRoutes: []
            }
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })

/**
 * The shared service that we can apply to root.
 */
export class SharedServiceModule {
    static forRoot(): ModuleWithProviders<SharedServiceModule> {
        return {
            ngModule: SharedServiceModule,
            providers: [
                LayoutService,
                InterceptService,
                FireBaseMessagingService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: InterceptService,
                    multi: true
                }
            ]
        };
    }
}
export * from './shared/layout.service';
export * from './shared/messaging.service';
