import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
    primaryText: string;
    primaryTextSecondLine: string;
    submitText: string;
    dismissText: string;
    titleText: string;
    leftAlign: boolean;
}

@Component({
    selector: 'app-dialog',
    templateUrl: 'dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class AppDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<AppDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) { }

    /**
     * close the dialog
     */
    onNoClick(): void {
        this.dialogRef.close(false);
    }

    /**
     * submit the dialog
     */
    onCTA(): void {
        this.dialogRef.close(true);
    }
}
