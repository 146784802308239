import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
/**
 * Loading service to trigger shimmer effect.
 */
@Injectable({ providedIn: 'root' })
export class LoadingService {
    private loading = new BehaviorSubject<boolean>(false);;
    loadingCounter = 0;
    /** observable to handle when loading is changed. */
    public loading$: Observable<boolean> = this.loading.asObservable();
    constructor() { }
    startLoading(): void {
        this.loadingCounter++;
        this.loading.next(true);
    }
    endLoading(): void {
        this.loadingCounter--;
        if (this.loadingCounter === 0) {
            this.loading.next(false);
        }
    }
}