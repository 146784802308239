import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

/**
 * Layout service to check if the user is mobile etc.
 */
@Injectable()
export class LayoutService {

    /** the users device */
    device = 'desktop';
    /** observable to handle if the device changed. */
    deviceSizeChanged$: Subject<string> = new Subject<string>();

    /**
     * Constructor calls to get the width
     */
    constructor(
        private router: Router,
        private location: Location
    ) {
        this.width();
    }

    /**
     * Returns the device of the user.
     */
    width(): void {
        const elem = (document.compatMode === 'CSS1Compat') ?
            document.documentElement :
            document.body;

        const width = elem.clientWidth;

        if (width >= 992) {
            this.device = 'desktop';
        }
        // this is tablet
        if (width < 992) {
            this.device = 'mobile';
        }
        if (width < 768) {
            this.device = 'mobile';
        }
    }

    /**
     * Go back on the top bar breadcrumb.
     * @param crumb breadcrumb
     * @param userId userId we are using
     */
    goBackBreadcrumb(crumb: string, userId = localStorage.getItem('cix_userId'), pastCrumb = '/home') {
        if (crumb === 'Profile and Settings') {
            // add some navigation params to pop the bottom sheet
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    profile: true
                }
            };
            this.router.navigate([pastCrumb], navigationExtras);
            return;
        }
        if (crumb === 'home') {
            this.router.navigate(['/home']);
            return;
        }
        if (crumb === 'Contact Information') {
            const navigationExtras: NavigationExtras = {
                queryParams: {
                    userId,
                    sourceScreen: pastCrumb,
                    breadcrumb: 'Profile and Settings'
                }
            };
            this.router.navigate(['/contact-information'], navigationExtras);
            return;
        }

        if (crumb === 'Back' || crumb === 'Cancel' || !crumb) {
            this.location.back();
            window.scrollTo(0, 0);
            return;
        }
        if (crumb) {
            this.location.back();
            window.scrollTo(0, 0);
        }
    }

    getDeviceType(): string {

        const elem = (document.compatMode === 'CSS1Compat') ?
            document.documentElement :
            document.body;

        const width = elem.clientWidth;
        let deviceTypeReturn = '';
        if (width >= 992) {
            deviceTypeReturn = 'Desktop';
        }
        // this is tablet
        if (width < 992) {
            deviceTypeReturn = 'Tablet';
        }
        if (width < 768) {
            deviceTypeReturn = 'Mobile';
        }

        return deviceTypeReturn;
    }

    /**
     * The device got resized and we can reemit that.
     */
    resize(): void {
        const previousDevice = this.device;
        this.width();

        if (this.device === previousDevice) {
            return;
        }

        this.deviceSizeChanged$.next(this.device);
    }
}
