import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AppDialogComponent } from '../../components/dialog/dialog.component';

@Injectable()
export class FireBaseMessagingService {

    currentMessage = new BehaviorSubject(null);

    constructor(
        private angularFireMessaging: AngularFireMessaging,
        private dialog: MatDialog,
        private router: Router) { }


    primeNotifications() {
        if (!('Notification' in window)) {
            alert('This browser does not support desktop notification');
            return;
        }
        if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
            // Otherwise, we need to ask the user for permission
            this.dialog.open(AppDialogComponent, {
                width: '350px',
                autoFocus: false,
                data: {
                    primaryText: `To get reminders on this device, you need to allow notifications.`,
                    submitText: 'Got It',
                    dismissText: 'Not Now',
                    titleText: 'Allow Notifications'
                }
            }).afterClosed().subscribe(result => {
                if (result && Notification.permission !== 'denied') {
                    this.requestPermission();
                    this.receiveMessage();
                } else if (result) {
                    this.showDeniedDialog();
                }
            });
        } else if (Notification.permission === 'denied') {
            this.showDeniedDialog();
        } else {
            this.requestPermission();
            this.receiveMessage();
        }
    }

    showDeniedDialog() {
        if (localStorage.getItem('cixDeniedNotification') === 'true') {
            return;
        }
        this.dialog.open(AppDialogComponent, {
            width: '350px',
            autoFocus: false,
            data: {
                primaryText: `Your notifications are off, so you're missing scheduled reminders. Turn on notifications in your device settings.`,
                titleText: 'Turn on notifications to get reminders',
                submitText: 'Contact Us',
                dismissText: 'Not Now'
            }
        }).afterClosed().subscribe(result => {
            localStorage.setItem('cixDeniedNotification', 'true');
            if (result) {
                this.router.navigate(['/contact-us']);
            }
        });
    }

    /**
     * request permission for notification from firebase cloud messaging
     *
     * @param userId userId
     */
    requestPermission() {
        this.angularFireMessaging.requestToken.subscribe(
            (token: any) => {
                localStorage.setItem('cix_firebasetoken_admin', token);
                this.updateToken(token);
            },
            (err) => {
                console.error('Unable to get permission to notify.', err);
            }
        );
    }

    deleteToken() {
        const tokenId: any = localStorage.getItem('cix_firebasetoken_admin');
        this.angularFireMessaging.deleteToken(tokenId);
        // this.usersService.removeFirebaseToken(tokenId).subscribe(obj => {
        //     localStorage.removeItem('cix_firebasetoken');
        // }, error => {
        //     localStorage.removeItem('cix_firebasetoken');
        // });
    }
    /**
     * update token in firebase database
     *
     * @param userId userId as a key
     * @param token token as a value
     */
    updateToken(token: any) {
        // we can change this function to request our backend service
        // this.usersService.saveUserFirebaseToken(token).subscribe();
    }

    /**
     * hook method when new notification received in foreground
     */
    receiveMessage() {
        this.angularFireMessaging.messages.subscribe(
            (payload: any) => {
                this.currentMessage.next(payload);
            });
    }
}
