import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export function getJWTHeaders() {
    return {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('cixhealthAdmin')
        })
    };
}

export function getCixClentJWTHeaders() {
    return {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('cixHealthClient')
        })
    };
}

export function getCixLoginJWTHeaders() {
    return {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('cixHealthClient'),
            'CixHealth.RememberDevice': ''
        })
    };
}

export const RequestConstants = {
    BASE_URL: environment.brokerApiUrl,
    CIX_CLIENT_BASE_URL: environment.cixClientBaseUrl
};

export function getFormDataJWTHeaders(version: string = '1.0') {
    const headersObj: {
        Authorization: string;
        'api-version'?: string;
    } = {
        Authorization: 'Bearer ' + localStorage.getItem('cixhealthAdmin')
    };
    if (version) {
        headersObj['api-version'] = version;
    }

    return {
        headers: new HttpHeaders(headersObj)
    };
}