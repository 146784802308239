import { Component, Inject } from '@angular/core';
import { SnackData } from '../snack-error/snack.error';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

// Utils


@Component({
    selector: 'app-snack-success',
    templateUrl: './snack.success.html',
    styleUrls: ['./snack.success.scss']
})
export class SnackSuccessComponent {
    darkMode: boolean = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackData,
        private snackRef: MatSnackBarRef<SnackSuccessComponent>,
    ) { }

    dismiss() {
        this.snackRef.dismiss();
    }
}
